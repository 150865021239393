var arr = [
  {
    title: 'الصفحة الرئيسية',
    route: 'home',
    icon: 'HomeIcon',
    per: "home"
  },
  {
    title: 'شاشة الباركود',
    route: 'barcode',
    icon: 'MonitorIcon',
    per: "barcode"
  },
  {
    title: 'شاشة البائع',
    route: 'seller',
    icon: 'CastIcon',
    per: "seller"
  },
  {
    title: 'الطلاب',
    icon: 'UsersIcon',
    per: "students",
    children: [
      {
        title: 'استعراض الطلاب',
        route: 'students/list'
      },
      {
        title: 'اضافة واستيراد الطلاب',
        route: 'students/add'
      },
      {
        title: 'طباعة كروت الطلاب',
        route: 'students/cards'
      },
    ],
  },
  {
    title: 'أرصدة الطلاب',
    route: 'balance',
    icon: 'DollarSignIcon',
    per: "balance"
  },
  {
    title: 'المشرفين',
    route: 'admins',
    icon: 'ShieldIcon',
    per: "admins"
  },
  {
    title: 'الاعدادات',
    route: 'settings',
    icon: 'SettingsIcon',
    per: "settings"
  },
  {
    title: 'تواصل معنا',
    route: 'contact',
    icon: 'PhoneIcon',
  },
  {
    title: 'شرح الاستخدام',
    href: 'https://brmja.com/howto#masrufi',
    icon: 'VideoIcon',
  },
  {
    title: 'تسجيل الخروج',
    route: 'logout',
    icon: 'LogOutIcon'
  },
], arr2 = [], user = JSON.parse(localStorage.getItem("user"));

arr.forEach(function (e) {

  if(user.pers){
    if(user.pers.includes("*") || user.pers.includes(e.per) || !e.per){
      arr2.push(e)
    }
  }else{
    arr2.push(e)
  }

})

export default arr2
